<template>
  <div
    id="presentationCard"
    ref="presentationCard"
    v-if="openCard"
    class="frosted-glass d-flex notification-ui"
    :class="{ open: panelOpen, closed: !panelOpen }"
  >
    <div
      class="exit-panel d-flex align-items-center justify-content-center"
      @click="panelOpen = true"
      title="close panel"
      v-if="panelOpen === false"
    >
      <div class="">+</div>
    </div>
    <div
      id="presentationPanel"
      class="d-flex flex-column flex-grow-1 p-2 text-left"
    >
      <div
        id="exitOpenButton"
        class="exit-button-layout right m-1"
        @click="panelOpen = false"
      >
        x
      </div>

      <div class="mt-2">
        <h3>{{ title }}</h3>
      </div>
      <div class="description-text text-lef">
        {{ description }}
      </div>
      <div class="d-flex justify-content-between mt-2">
        <b-btn @click="increment(false)">prev</b-btn>
        <div id="counter" class="float-right mt-2 font-sm font-light">
          {{ currentIndex + 1 }}/ {{ narrativeLength }}
        </div>
        <b-btn @click="increment(true)">next </b-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'presentationCard',
  data () {
    return {
      currentIndex: 0,
      panelOpen: true
    }
  },
  computed: {
    ...mapGetters({
      narrativeMode: 'getNarrativeMode',
      metrics: 'getMetricObject',
      metricSelected: 'getMetricSelected',
      presentationObject: 'getPresentationObject'
    }),
    openCard () {
      return this.narrativeMode && !this.landingCard
    },
    narrativeObject () {
      return this.presentationObject.narrative
    },
    currentObject () {
      return this.narrativeObject[this.currentIndex]
    },
    title () {
      return this.currentObject.title
    },
    description () {
      return this.currentObject.description
    },
    narrativeLength () {
      return this.narrativeObject.length
    },
    landingCard () {
      return this.presentationObject.landingCard
    }
  },
  methods: {
    setModel () {
      // reset metrics toggles
      this.$store.commit('resetMetricToggles', true)
      // set camera position
      this.$store.commit(
        'setCameraPosition',
        this.currentObject.cameraPosition
      )
      // set controls panel open/close
      this.$store.commit('setExplorePanel', [
        'Controls',
        this.currentObject.explorePanel
      ])
      /**
       * if there is a metric and it is on
       * turn it off
       */

      if (this.metricSelected != null && this.metricSelected.status === true) {
        this.$store.commit('setMetricSelected', {
          status: false,
          analysis_mesh: this.metricSelected.analysis_mesh,
          metric: this.metricSelected.metric
        })
      }

      this.$store.commit('setSearchIterationID', this.currentObject.modelID)

      /**
       * check to see the next iteration in the presentation has a analysis mesh
       * if object is empty i.e. key length < 1
       * if objects does not have metric, send selectMetric function an update with
       * status, and turn off the previous iteration
       */

      const metricObject = this.currentObject.metric

      if (Object.keys(metricObject).length > 0) {
        this.$parent.$children.forEach((d, i) => {
          if (d.$refs.hasOwnProperty('inputSelector')) {
            this.metrics[metricObject['metric']]['toggle'] = true

            d.$refs['inputSelector'].selectMetric(
              this.metrics[metricObject['metric']]
            )
          }
        })
      }
    },
    increment (increase) {
      if (increase && this.currentIndex < this.narrativeLength - 1) {
        this.currentIndex += 1
      } else if (!increase && this.currentIndex > 0) {
        this.currentIndex -= 1
      }

      this.setModel()
    }
  }
}
</script>

<style lang="scss">
$card-width: 325px;
#presentationCard {
  position: absolute;

  width: $card-width;
  bottom: $navbar-height;

  border-radius: 2px;

  border: 1px solid $off-white;

  transition: all $short-transition;

  #presentationPanel {
    width: $card-width;
  }
  &.open {
    right: $navbar-height;
  }
  &.closed {
    right: calc(-#{$card-width} + 50px);
  }
  .exit-panel {
    width: 50px;
    background: $blue;
    cursor: pointer;

    &:hover {
      background: white;
      color: $blue;
    }
  }
}
</style>
