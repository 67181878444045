import Vue from 'vue'
import Vuex from 'vuex'
// async library for API calls
import axios from 'axios'

import {
  createInputObject,
  createMetricObject
} from '@/assets/js/dataobjects.js'

import {
  customData
} from '@/assets/js/library.js'

import {
  version
} from '../../package.json'

import uiModule from './modules/ui'
import sceneModule from './modules/scene'

import {
  OPTIMUS
} from '@/assets/js/api.js'

const {
  APIURL,
  PROJECTBUCKET,
  RHINOAPI
} = OPTIMUS

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    ui: uiModule,
    scene: sceneModule
  },
  state: {
    appVersion: version || '0',
    // contains model csv data
    modelData: [],
    // boolean to flag whether  model data is loaded into application
    modelDataFlag: false,
    // active model data object
    modelDataObject: {},
    // global settings file
    settings: null,
    // settings metrics object with metric specific info
    metricObject: [],
    // input specific info
    inputHeaders: [],
    // metric header columns
    metricHeaders: [],
    // model settings local object
    modelSettings: [],
    // input settings object
    inputObject: [],
    // AWS project Location
    projectLocation: '',
    // AWS model location
    modelLocation: '',
    // project URL
    projectURL: {},
    // project object scale
    projectScale: 1,
    // notification object
    notificationObject: {
      flag: false,
      message: '',
      type: null
    },
    narrativeFlag: false,
    narrativeMode: false,
    scoutMode: 'custom',
    presentationObject: {
      'title': '',
      'description': '',
      'narrative': [],
      'landingCard': true
    },
    // constructs url to send for model
    urlConstructor: function (modelID, APIURL, modelLocation, metric, metricName) {
      var model = null

      if (metric === true) {
        model = modelID + '_' + metricName + '.json'
      } else {
        model = modelID + '_option.json'
      }

      return axios({
        method: 'post',
        url: `${APIURL}/getModelByID`,
        data: {
          'Bucket': PROJECTBUCKET,
          'Key': `${modelLocation}/${model}`
        }
      })
    }
  },
  getters: {
    getNarrativeFlag: state => {
      return state.narrativeFlag
    },
    getScoutMode: state => {
      return state.scoutMode
    },
    getNotificationObject: state => {
      return state.notificationObject
    },
    getAppVersion: state => {
      return state.appVersion
    },
    getModelDataObject: state => {
      return state.modelDataObject
    },
    getModelSettings: state => {
      return state.modelSettings
    },
    getModelDataFlag: state => {
      return state.modelDataFlag
    },
    getModelData: state => {
      return state.modelData
    },
    getSettingsData: state => {
      return state.settings
    },
    getMetricObject: state => {
      return state.metricObject
    },
    getInputHeaders: state => {
      return state.inputHeaders
    },
    getMetricHeaders: state => {
      return state.metricHeaders
    },
    getInputObject: state => {
      return Object.freeze(state.inputObject)
    },
    getProjectLocation: state => {
      return state.projectLocation
    },
    getModelLocation: state => {
      return state.modelLocation
    },
    getProjectScale: state => {
      return state.projectScale
    },
    getProjectURL: state => {
      return state.projectURL
    },
    getNarrativeMode: state => {
      return state.narrativeMode
    },
    getPresentationObject: state => {
      return state.presentationObject
    }
  },
  mutations: {
    setPresentationObject (state, presentationObject) {
      state.presentationObject = presentationObject
    },
    setNarrativeFlag (state, narrativeFlag) {
      state.narrativeFlag = narrativeFlag
    },
    setScoutMode (state, newScoutMode) {
      state.scoutMode = newScoutMode
    },
    setNotificationObject (state, newNotificationObject) {
      state.notificationObject = newNotificationObject
    },
    setModelDataObject (state, newModelDataObject) {
      state.modelDataObject = newModelDataObject
    },
    setModelData (state, newModelData) {
      state.modelData = newModelData
    },
    setSettings (state, newSettings) {
      state.settings = newSettings
    },
    setMetricObject (state, newMetricObject) {
      state.metricObject = newMetricObject
    },
    setInputObject (state, newInputObject) {
      state.inputObject = newInputObject
    },
    setInputHeaders (state, inputHeaders) {
      state.inputHeaders = inputHeaders
    },
    setMetricHeaders (state, metricHeaders) {
      state.metricHeaders = metricHeaders
    },
    setModelSettings (state, modelSettings) {
      state.modelSettings = modelSettings
    },
    setModelDataFlag (state, modelDataFlag) {
      state.modelDataFlag = modelDataFlag
    },
    setProjectLocation (state, projectLocation) {
      state.projectLocation = projectLocation
    },
    setModelLocation (state, modelLocation) {
      state.modelLocation = modelLocation
    },
    resetMetricToggles (state) {
      state.metricHeaders.forEach((key) => {
        state.metricObject[key].toggle = false
      })
    },
    setProjectScale (state, newProjectScale) {
      state.projectScale = newProjectScale
    },
    setProjectURL (state, newProjectURL) {
      state.projectURL = newProjectURL
    },
    setNarrativeMode (state, narrativeMode) {
      state.narrativeMode = narrativeMode
    }
  },
  actions: {
    getContextModelData: function (context, modelNames) {
      let modelPromises = []

      modelNames.forEach((modelName) => {
        modelPromises.push(axios({
          method: 'post',
          url: `${APIURL}/getModelByID`,
          data: {
            'Bucket': PROJECTBUCKET,
            'Key': 'projects/404/models/' + modelName + '.json'
          }
        }))
      })

      return new Promise((resolve) => {
        axios.all(modelPromises).then(responseArr => {
          const parseModels = responseArr.map((response) => {
            let d = JSON.parse(response.data.model)

            d['metadata']['ID'] = response.data.ID
            return d
          })

          resolve(parseModels)
        })
      })
    },
    getPageNotFoundData: async function (context) {
      const projectLocation = 'projects/404/'

      let contextObject = await axios.post(`${APIURL}/getModelByID`, {
        'Bucket': PROJECTBUCKET,
        'Key': projectLocation + 'models/context.json'
      })

      contextObject = Object.assign(JSON.parse(contextObject.data.model), {
        _isVue: true
      })

      let settingsData = await axios.post(`${APIURL}/getModelByID`, {
        'Bucket': PROJECTBUCKET,
        'Key': projectLocation + 'settings.json'
      })

      settingsData = JSON.parse(settingsData.data.model)

      let modelData = await axios.post(`${APIURL}/getModelData`, {
        'Bucket': PROJECTBUCKET,
        'Key': projectLocation + 'data.csv'
      })

      modelData = modelData.data.model

      const [inputStatus, inputMessage, inputHeaders, inputObject] = createInputObject(modelData, settingsData['inputInfo'], '0')

      if (!inputStatus) {
        this.$store.commit('setNotificationObject', {
          flag: true,
          message: inputMessage,
          type: 'error'
        })

        throw new Error('input headers are incorrect')
      }

      let contextModelData = await axios.post(APIURL + 'getModelData/', {
        'Bucket': PROJECTBUCKET,
        'Key': projectLocation + 'model_data.csv'
      })

      contextModelData = contextModelData.data.model

      return {
        inputHeaders: inputHeaders,
        inputObject: inputObject,
        modelData: modelData,
        contextObject: contextObject,
        contextModelData: contextModelData
      }
    },
    /**
     *
     * @param {*} context store context
     * @param {*} requestObject request modelID
     */
    readMultipleModelsByID: function (context, requestObject) {
      let promises = []
      let metrics = []
      // let setter = requestObject['setter']
      const {
        models,
        metric,
        metricName,
        setter
      } = requestObject

      switch (context.state.scoutMode) {
        case 'custom':
          // create an array of promises
          models.forEach((model) => {
            let url = context.state.urlConstructor(model, APIURL, context.state.modelLocation, false, '')

            promises.push(url)

            if (metric === true) {
              url = context.state.urlConstructor(model, APIURL, context.state.modelLocation, metric, metricName)
              metrics.push(url)
            }
          })
          // call all promises concurrently
          // only return when all have been returned
          axios.all(promises)
            .then(responseArr => {
              const gridModels = responseArr.map((response) => {
                let d = JSON.parse(response.data.model)

                d['metadata']['ID'] = response.data.ID
                return d
              })
              const responseObject = {
                'models': gridModels,
                'metrics': []
              }

              context.commit(setter, Object.assign(responseObject, {
                _isVue: true
              }))
              // axios.all(metrics)
              //   .then(responseArr => {
              //     const metricModels = responseArr.map((response) => {
              //       return JSON.parse(response.data.model)
              //     })

              //     const responseObject = {
              //       'models': gridModels,
              //       'metrics': metricModels
              //     }

              //   })
            })
          break

        case 'public':

          const gridModels = models.map((ID) => {
            return customData[ID + '_option.json']
          })

          const responseObject = {
            'models': gridModels,
            'metrics': []
          }

          context.commit(setter, Object.assign(responseObject, {
            _isVue: true
          }))

          break
      }
    },
    /**
     *
     * @param {*} context store context
     * @param {*} requestObject JSON Array with object get weighted average from
     */
    readModelIDsByWeightedAverage: async function (context, requestObject) {
      const params = {
        'data': requestObject.data,
        'weighted_metrics': requestObject.weighted_metrics
      }
      const response = await axios.post('https://megatron.kpfui.dev/getModelIDsByWeightedAverage', params)

      context.commit('setModelIDs', response.data['modelIDs'])
    },
    getModelByID: async function (context, requestObject) {
      const [ID] = requestObject

      switch (context.state.scoutMode) {
        case 'custom':
          const params = {
            'Bucket': PROJECTBUCKET,
            'Key': `${context.state.modelLocation}/${ID}`
          }
          const response = await axios.post(`${APIURL}/getModelByID`, params)

          response.data.model = JSON.parse(response.data.model)

          return response

        case 'public':

          return {
            'data': {
              ID: ID,
              model: customData[ID]
            }
          }
      }
    },
    /**
     *
     * @param {*} context store context
     * @param {*} requestObject request ModelID
     */
    readModelByID: function (context, requestObject) {
      const {
        ID,
        type
      } = requestObject

      switch (context.state.scoutMode) {
        case 'custom':
          axios.post(`${APIURL}/getModelByID`, {
            'Bucket': PROJECTBUCKET,
            'Key': `${context.state.modelLocation}/${requestObject.ID}.json`
          }).then((response) => {
            if (type === 'model') {
              context.commit('setModelID', requestObject.ID.split('_option')[0])
              context.commit('setSelectedModel', Object.assign(JSON.parse(response.data.model), {
                _isVue: true
              }))
            } else {
              context.commit('setMetric', Object.assign(JSON.parse(response.data.model), {
                _isVue: true
              }))
            }
          })
          break

        case 'public':

          if (type === 'model') {
            context.commit('setModelID', ID.split('_option')[0])
            context.commit('setSelectedModel', customData[ID + '.json'])
          } else {
            context.commit('setMetric', customData[ID + '.json'])
          }
          break
      }
    },
    checkNarrativeID: async function (context, narrativeID) {
      const params = {
        'narrativeID': narrativeID
      }

      const response = await axios.post('https://optimus.kpfui.dev/checkNarrativeID', params)

      return response.data.message
    },
    createNarrative: async function (context, payload) {
      payload.projectLocation = context.state.projectURL
      payload.creationDate = Date.now()

      const params = {
        'data': payload
      }

      const response = await axios.post('https://optimus.kpfui.dev/createNarrative', params)

      return response
    },
    /**
     *
     * @param {*} context store context
     * @param {*} queryParams contains current request project data
     * Only fires on application init
     *  creates input + metric objects to create UI
     */
    readModelData: async function (context, urlParams) {
      const { folder, project, narrativeID } = urlParams
      const projectLocation = `${project}/${folder}`
      const modelLocation = `${projectLocation}/models`

      context.commit('setProjectLocation', projectLocation)
      context.commit('setModelLocation', modelLocation)
      context.commit('setProjectURL', urlParams.project)

      // let defaultModelIndex = '0'

      document.cookie = `projectPath=${projectLocation}`

      const projectData = await axios.post(`${RHINOAPI}/v1/scout/read/project`, {
        query: {
          project: project,
          folder: folder
        },
        projection: {
          matrix: 1,
          settings: 1
        },
        sort: {}
      })

      const { matrix, settings } = projectData.data.projects[0]

      const modelData = matrix.data.map((d) => {
        const o = {}
        d.forEach((e, i) => {
          o[matrix.headers[i]] = e
        })
        return o
      })

      // sorts the data labels for in_design_name to get first model
      let t = Object.assign([], modelData).sort((a, b) => {
        var nameA = a.in_design_name.toUpperCase()
        var nameB = b.in_design_name.toUpperCase()

        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        // names must be equal
        return 0
      })

      let defaultModelIndex = t[0].iteration.toString()

      if (narrativeID !== undefined) {
        let response = await axios.post('https://optimus.kpfui.dev/getNarrativeByID', {
          'narrativeID': narrativeID
        })

        if (response.status === 200) {
          const narrativeObject = response.data.narrativeObject

          const presentationObject = {
            narrative: [],
            title: narrativeObject.narrativeTitle,
            description: narrativeObject.narrativeDescription,
            landingCard: true
          }

          narrativeObject.narrative.forEach((e) => {
            presentationObject.narrative.push(e)
          })

          defaultModelIndex = presentationObject.narrative[0].modelID

          context.commit('setPresentationObject', presentationObject)
          context.commit('setNarrativeMode', true)
          context.commit('setExplorePanel', ['Controls', presentationObject.narrative[0].explorePanel])
        }
      }

      try {
        const projectScale = settings['projectSettings'] === undefined ? 1 : settings['projectSettings']['scale']

        context.commit('setProjectScale', projectScale)
      } catch (error) {
        console.debug('project scale undefined')
      }
      // set the settings labels equal to the in_design_name option in the matrix
      settings['inputInfo']['in_design_name']['labels'] = matrix.data.map((d) => d[1])

      const inputSettings = settings['inputInfo']
      const metricSettings = settings['metricInfo']
      const [inputStatus, inputMessage, inputHeaders, inputObject] = createInputObject(modelData, inputSettings, defaultModelIndex)
      const [metricStatus, metricMessage, metricHeaders, metricObject] = createMetricObject(modelData, metricSettings)

      if (!inputStatus) {
        context.commit('setNotificationObject', {
          flag: true,
          message: inputMessage,
          type: 'error'
        })

        throw new Error('input headers are incorrect')
      }

      if (!metricStatus) {
        context.commit('setNotificationObject', {
          flag: true,
          message: metricMessage,
          type: 'error'
        })

        throw new Error('metric headers are incorrect')
      }

      let iterationArray = modelData.map((d) => d['iteration'])

      let contextJSON = await axios.post(`${APIURL}/getModelByID`, {
        'Bucket': PROJECTBUCKET,
        'Key': `${modelLocation}/context.json`
      })

      context.commit('setContextObject', Object.assign(JSON.parse(contextJSON.data.model), {
        _isVue: true
      }))

      // remap keyed object to array of objects for rendering
      context.commit('setInputObject', inputObject)
      // commit metric data to store
      context.commit('setMetricObject', metricObject)
      // commit changes to store
      context.commit('setInputHeaders', inputHeaders)
      context.commit('setMetricHeaders', metricHeaders)
      // get last iteration ID
      context.commit('setNumberOfModels', iterationArray)
      // set model data
      context.commit('setModelData', modelData)
      // set model data loaded flag
      context.commit('setModelDataFlag', true)
      // set settings loaded flag
      context.commit('setSettings', metricSettings)
    }
  }
})
