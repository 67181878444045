<template>
  <div id="notification" v-if="notificationObject.flag" class="notification-ui"  :class="{'error': notificationType === 'error' , 'success': notificationType ==='success'}">

    <div class="icon-box d-flex-content-center">
      <div class="error icon-circle" v-if="notificationType === 'error'">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="exclamation" class="svg-inline--fa fa-exclamation fa-w-6" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 192 512"><path fill="currentColor" d="M176 432c0 44.112-35.888 80-80 80s-80-35.888-80-80 35.888-80 80-80 80 35.888 80 80zM25.26 25.199l13.6 272C39.499 309.972 50.041 320 62.83 320h66.34c12.789 0 23.331-10.028 23.97-22.801l13.6-272C167.425 11.49 156.496 0 142.77 0H49.23C35.504 0 24.575 11.49 25.26 25.199z"></path></svg>
      </div>
      <div class="success icon-circle" v-if="notificationType === 'success'">
        <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="check" class="svg-inline--fa fa-check fa-w-16" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"></path></svg>
      </div>
    </div>

    <div class="message-box text-left p-2 flex-grow-1">
      <div class="title">
        {{notificationHeader}}
      </div>
      <div class="description-text">
        {{notificationMessage}}
      </div>
    </div>

    <div class="close-button d-flex-content-center" @click="closeNotification" title="close">
      <svg aria-hidden="true" focusable="false" data-prefix="fas" data-icon="times" class="svg-inline--fa fa-times fa-w-11" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 352 512"><path fill="currentColor" d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z"></path></svg>
    </div>

  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'notification',
  computed: {
    ...mapGetters({
      notificationObject: 'getNotificationObject'
    }),
    notificationType () {
      return this.notificationObject.type
    },
    notificationMessage () {
      return this.notificationObject.message
    },
    errorHeader () {
      return 'oh snap!'
    },
    successHeader () {
      return 'wohoo!'
    },
    notificationHeader () {
      switch (this.notificationType) {
        case 'error':
          return this.errorHeader

        case 'success':
          return this.successHeader

        default:
          return ''
      }
    }
  },
  methods: {
    closeNotification () {
      this.$store.commit('setNotificationObject', {
        flag: false
      })
    }
  }
}
</script>

<style lang="scss">

$icon-circle-size: 30px;

#notification{
  position: absolute;
  display: flex;
  color: $off-white;

  width: 325px;

  bottom: $navbar-height;
  right: $navbar-height;

  border-radius: 2px;

  &.error{
    background: $highlight-color;

    .icon-box{
      @include lightenBackground($highlight-color, 5%);
    }
  }

  &.success{
    background: $blue;

    .icon-box{
      @include lightenBackground($blue, 5%);
    }
  }

  .close-button{
    width: 30px;
    cursor: pointer;

    svg{
      height: 15px;
    }
  }
  .close-button:hover path{
    fill: darken($off-white, 10%);
  }

  .icon-circle{
    border-radius: 50%;
    width: $icon-circle-size;
    height: $icon-circle-size;
    vertical-align: middle;
    line-height: $icon-circle-size;
  }

  .icon-box{
    width: 50px;

    svg{
      height: 15px
    }
  }
}
</style>
