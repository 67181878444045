export default {
  state: {
    // current model ID
    modelID: 0,
    // contains metric mesh
    metric: null,
    // context object storage
    contextObject: {},
    // camera position
    cameraRadius: [],
    // max number of models in design space
    numberOfModels: 0,
    // number of models in collection
    collectionIDs: [],
    // collection of obj models from API for the collection=
    collectionModels: {
      'models': []
    },
    // models to be used on parallel grid
    gridModels: [],
    // given models for filter mode
    modelIDs: [],
    // user selected metric selected
    metricSelected: null,
    // currently selected model
    selectedModel: [],
    // search iteration modelID
    searchIterationID: null,
    // collection update flag
    updateParentSceneFlag: false,
    cameraPosition: { x: 0, y: 0, z: 0 }
  },
  getters: {
    getModelID: state => {
      return state.modelID
    },
    getMetric: state => {
      return Object.freeze(state.metric)
    },
    getCameraRadius: state => {
      return state.cameraRadius
    },
    getContextObject: state => {
      return state.contextObject
    },
    getNumberOfModels: state => {
      return state.numberOfModels
    },
    getSearchIterationID: state => {
      return state.searchIterationID
    },
    getSelectedModel: state => {
      return state.selectedModel
    },
    getMetricSelected: state => {
      return state.metricSelected
    },
    getModelIDs: state => {
      return state.modelIDs
    },
    getCollectionIDs: state => {
      return state.collectionIDs
    },
    getCollectionModelCount: state => {
      return state.collectionIDs.length
    },
    getCollectionModels: state => {
      return state.collectionModels
    },
    getGridModels: state => {
      return state.gridModels
    },
    getUpdateParentSceneFlag: state => {
      return state.updateParentSceneFlag
    },
    getCameraPosition: state => {
      return state.cameraPosition
    }
  },
  mutations: {
    setModelID (state, newModelID) {
      state.modelID = newModelID
    },
    /**
     *
     * @param {Object} state store state
     * @param {addToCollectionIDs} addToCollectionIDs array [ boolean, collectionID ]
     */
    setCollectionIDs (state, addToCollectionIDs) {
      if (addToCollectionIDs[0]) {
        state.collectionIDs.push(addToCollectionIDs[1])
      } else {
        state.collectionIDs = state.collectionIDs.filter(item => item !== addToCollectionIDs[1])
      }
    },
    setCollectionModels (state, newCollectionModels) {
      state.collectionModels = newCollectionModels
    },
    setContextObject (state, contextObject) {
      state.contextObject = contextObject
    },
    setNumberOfModels (state, numberOfModels) {
      state.numberOfModels = numberOfModels
    },
    setMetric (state, metric) {
      state.metric = metric
    },
    setSearchIterationID (state, searchIterationID) {
      state.searchIterationID = searchIterationID
    },
    setCameraRadius (state, cameraRadius) {
      state.cameraRadius = cameraRadius
    },
    setGridModels (state, gridModels) {
      state.gridModels = gridModels
    },
    setModelIDs (state, modelIDs) {
      state.modelIDs = modelIDs
    },
    setSelectedModel (state, selectedModel) {
      state.selectedModel = selectedModel
    },
    setMetricSelected (state, metricSelected) {
      state.metricSelected = metricSelected
    },
    setUpdateParentSceneFlag (state, newUpdateParentSceneFlag) {
      state.updateParentSceneFlag = newUpdateParentSceneFlag
    },
    setCameraPosition (state, cameraPosition) {
      state.cameraPosition = cameraPosition
    }
  },
  actions: {
    updateCollectionIDs: function (context, collectionID) {
      const collectionIDsTest = !context.state.collectionIDs.includes(collectionID)
      if (collectionIDsTest) {
        context.commit('setCollectionIDs', [collectionIDsTest, collectionID])
      } else {
        context.commit('setCollectionIDs', [collectionIDsTest, collectionID])
        context.commit('setUpdateParentSceneFlag', true)
      }
    }
  }
}
