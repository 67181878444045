<template>
  <div id="presentationLandingCard" ref="presentationLandingCard" v-if="openCard" class="d-flex-content-center">
    <div id="presentationBox" class="frosted-glass p-4 notification-ui">
      <h2>{{title}}</h2>
      <p>{{description}}</p>
      <p>
        Welcome to Scout narrative mode.
      </p>
      <b-btn @click="landingCard = !landingCard" variant="primary">Start Presentation</b-btn>
      <b-btn @click="open = !open" variant="secondary">Close</b-btn>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'

export default {
  name: 'presentationLandingCard',
  data () {
    return {
      open: true
    }
  },
  computed: {
    ...mapGetters({
      narrativeMode: 'getNarrativeMode',
      presentationObject: 'getPresentationObject'
    }),
    openCard () {
      return this.narrativeMode && this.landingCard && this.open
    },
    title () {
      return this.presentationObject.title
    },
    description () {
      return this.presentationObject.description
    },
    landingCard: {
      get () {
        return this.presentationObject.landingCard
      },
      set (value) {
        let p = this.presentationObject
        p.landingCard = value
        this.$store.commit('setPresentationObject', p)
      }
    }
  }
}
</script>

<style lang="scss">
#presentationLandingCard{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  pointer-events: none;

  #presentationBox{
    width: 50vw;
    pointer-events: all;
  }
}
</style>
