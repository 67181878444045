import Vue from 'vue'
import Router from 'vue-router'

import store from './store/store.js'

import {
  getProjectPathCookie
} from '@/assets/js/helper.js'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [{
    path: '/',
    name: 'Explore',
    component: () => import('./pages/Model.vue'),
    link: true,
    mobile: true,
    presentationMode: true
  },
  {
    path: '*',
    component: () => import('./pages/pageNotFound.vue')
  },
  {
    path: '/about',
    name: 'About',
    component: () => import('./pages/About.vue'),
    link: true,
    mobile: true,
    presentationMode: true
  },
  {
    path: '/filter',
    name: 'Filter',
    component: () => import('./pages/Filter.vue'),
    link: true,
    meta: {
      requiresModelData: true
    },
    mobile: false,
    presentationMode: true
  },
  {
    path: '/compare',
    name: 'Compare',
    component: () => import('./pages/Compare.vue'),
    link: false,
    mobile: false,
    presentationMode: true
  },
  {
    path: '/narrative',
    name: 'Narrative',
    component: () => import('./pages/Narrative.vue'),
    link: false,
    mobile: false,
    presentationMode: false
  },
  {
    path: '/collection',
    name: 'Collection',
    component: () => import('./pages/Collection.vue'),
    link: false,
    mobile: false,
    presentationMode: true
  }
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresModelData)) {
    if (!store.getters.getModelDataFlag) {
      next({
        path: '/',
        query: {
          project: getProjectPathCookie()
        }
      })
    } else {
      next()
    }
  } else {
    next() // make sure to always call next()!
  }
})
export default router
